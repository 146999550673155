<template>
    <Toast />
    <div class="grid">
        <div class="col-12">
            <div class="card">

                <div class="formgrid grid">
                    <div class="field">
                        <label for="arquivo">Anexo</label>
                        <FileUpload chooseLabel="Selecionar arquivo (200mb máx)." ref="arquivo" mode="basic"
                            name="arquivo" v-on:change="onChangeFileUpload()" />
                            <Button  severity="success" type="button" style="margin-top: 18px;" class="mr-2 mb-2 sucess" label="Importar" icon="pi pi-send" :loading="loading" @click="importar()" />
                    </div>

                </div>

            </div>
        </div>
    </div>

    <Dialog v-model:visible="logDialog" :style="{ width: '800px' }" header="Log Retorno BRNotas" :modal="true"
        class="p-fluid">
        <pre>
    <code>
      {{ log }}
    </code>
  </pre>
    </Dialog>

</template>

<script>

import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            log: "",
            arquivo: "",
            loading: 0,
            logDialog: false,
            mes: new Date().getMonth() + 1,
            ano: new Date().getFullYear(),
            meses: [
                { label: "Janeiro", value: "1" },
                { label: "Fevereiro", value: "2" },
                { label: "Março", value: "3" },
                { label: "Abril", value: "4" },
                { label: "Maio", value: "5" },
                { label: "Junho", value: "6" },
                { label: "Julho", value: "7" },
                { label: "Agosto", value: "8" },
                { label: "Setembro", value: "9" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" }

            ],
            anos: [],
            faturas: [],
            fatura: {},

            filters: {},
            submitted: false,



        };
    },

    created() {
        this.initFilters();
    },
    mounted() {

    },


    methods: {

        onChangeFileUpload() {
            // Obter o arquivo selecionado pelo usuário
            const arquivoSelecionado = this.$refs.arquivo.files[0];

            // Verificar se um arquivo foi selecionado
            if (arquivoSelecionado) {
                // Obter o nome original do arquivo
                const nomeOriginal = arquivoSelecionado.name;

                // Remover caracteres especiais do nome do arquivo
                const nomeArquivo = nomeOriginal.replace(/[^\w\d\s.]/g, '');

                // Imprimir o novo nome do arquivo
                console.log('Novo nome do arquivo:', nomeArquivo);

                // Criar um novo objeto de arquivo com o nome limpo
                this.arquivo = new File([arquivoSelecionado], nomeArquivo, {
                    type: arquivoSelecionado.type
                });
            }
        },

        mostrarLog(data) {
            this.logDialog = true;
            this.log = data.log_retorno;
        },

        importar() {

            this.loading = true;
            let self = this;
           
            let formData = new FormData();
            formData.append("arquivo", this.arquivo);

            this.axios
                .post("/xml/importar", formData)
                .then(() => {
                    this.loading = false;
                 
                    self.$toast.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "XML Implantado com sucesso!",
                        life: 3000,
                    });

                    this.arquivo = "";
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                    this.loading = false;
                });

        },





        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>